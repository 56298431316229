import React from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';
import { FaDownload, FaFacebookMessenger, FaBeer, FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';

export function MainNavbar(props) {
  return (
    <div className="MainNavbar">
      <div className="nav-link">
        <NavLink exact to="/" className="link">Info</NavLink>
        <NavLink to="/web" className="link">Web Dev</NavLink>
        <NavLink to="/mobile" className="link">Mobile Dev</NavLink>
        <NavLink to="/other" className="link">Other Design</NavLink>
      </div>
      <div className="nav-action">
        <a href={"#"} className="btn btn-download" target="_blank">
          <FaDownload className="icon" />
          <span className="label">Download CV</span>
        </a>
        <a href="#" className="btn btn-message" target="_blank">
          <FaWhatsapp className="icon" />
          <span className="label">Whatsapp</span>
        </a>
      </div>
    </div>
  );
}