import React from 'react';
import { CoverStar, CoverWhite, ProfileWhite, ProfileBlack } from '../../../assets/images';
import './style.scss';

export function MainHeader(props) {
  return (
    <div className="MainHeader">
      <div className="head-cover">
        <img src={CoverWhite} />
      </div>
      <div className="head-detail">
        <div className="ava">
          <img src={ProfileBlack} />
        </div>
        <h2 className="name">Labieb Hammam Nurri</h2>
        <div className="as">Front-End Engineer, Bandung, Indonesia</div>
      </div>
    </div>
  );
}