import React from 'react';
import { Helmet } from 'react-helmet';
import './style.scss';

function OtherPage(props) {
  return (
    <>
      <Helmet>
        <title>Other Design | Labieb Hammam Nurri</title>
      </Helmet>
      <div className="OtherPage">
        <h1>COMING SOON</h1>
      </div>
    </>
  );
}

export default OtherPage;