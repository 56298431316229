import React from 'react';
import { Helmet } from 'react-helmet';
import { FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaQuora, FaTwitter, FaYoutube } from 'react-icons/fa';
import './style.scss'

function InfoPage(props) {
  return (
    <div className="InfoPage">
      <Helmet>
        <title>Labieb Hammam Nurri</title>
      </Helmet>
      <div className="container">
        <div className="info-title">About me</div>
        <p className="info-body">I am web developer form Bandung City who delves into JavaScript programming and likes Web development in the Front-End Developer section.</p>
      </div>
      <div className="container">
        <div className="info-title">Skills</div>
        <div className="info-body body-flex list-flex">
          <ul className="skill-container">
            <li>HTML, CSS, JavaScript</li>
            <li>React JS</li>
            <li>React Native</li>
          </ul>
          <ul className="skill-container">
            <li>Node.js</li>
            <li>PHP</li>
            <li>MySQL</li>
          </ul>
        </div>
      </div>
      <div className="container">
        <div className="info-title">Social Media</div>
        <div className="info-body body-flex">
          <a href="https://www.instagram.com/labiebhn_" target="_blank" className="link-icon">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/in/labiebhn" target="_blank" className="link-icon">
            <FaLinkedin />
          </a>
          <a href="https://github.com/labiebhn" target="_blank" className="link-icon">
            <FaGithub />
          </a>
          <a href="https://www.youtube.com/channel/UCMuNqLyySguKh6CngB_7o6Q" target="_blank" className="link-icon">
            <FaYoutube />
          </a>
          <a href="https://www.facebook.com/labiebhn" target="_blank" className="link-icon">
            <FaFacebook />
          </a>
          <a href="https://twitter.com/labiebhn" target="_blank" className="link-icon">
            <FaTwitter />
          </a>
          <a href="https://id.quora.com/profile/Labieb-Hammam-Nurri" target="_blank" className="link-icon">
            <FaQuora />
          </a>
        </div>
      </div>
    </div>
  );
}

export default InfoPage;