import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.scss';
import { UpButton } from "./components/Buttons/UpButton";
import { MainFooter } from "./components/Footers/MainFooter";
import { MainHeader } from './components/Headers';
import { MainNavbar } from './components/Navbars';
import InfoPage from "./pages/InfoPage";
import MobilePage from "./pages/MobilePage";
import OtherPage from "./pages/OtherPage";
import WebPage from "./pages/WebPage";

function App() {
  return (
    <Router>
      <div className="App">
        <MainHeader />
        <main className="main-container">
          <div className="content-container">
            <MainNavbar />
            <Switch>
              <div className="page-container">
                <Route exact path="/">
                  <InfoPage />
                </Route>
                <Route exact path="/web">
                  <WebPage />
                </Route>
                <Route exact path="/mobile">
                  <MobilePage />
                </Route>
                <Route exact path="/other">
                  <OtherPage />
                </Route>
              </div>
            </Switch>
          </div>
        </main>
        <UpButton />
        <MainFooter />
      </div>
    </Router>
  );
}

export default App;
