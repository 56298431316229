import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ContentKitapanen, ContentSira } from '../../assets/mobile-content';
import { MainCard } from '../../components/Cards';
import { MainModal } from '../../components/Modals';
import './style.scss';

function MobilePage({  }) {

  // state
  const [show, setShow] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [data, setData] = useState([
    {
      img: ContentSira,
      name: 'SIRA',
      link: 'https://www.instagram.com/p/CJ0X6QgHpny/',
      description: `SIRA (Budget Realization Information System) is an application that aims to automatically generate budget realization reports by implementing a system such as Classroom which functions to accommodate all ongoing transactions and supervise every transaction in the use of a given budget and can include members involved in realizing it. budget on these activities. The SIRA project was developed by a hackathon team formed from lecturers to represent the computerized accounting study program.`,
      role: 'App Developer',
      technology: 'React Native, Laravel, MySQL',
    },
    {
      img: ContentKitapanen,
      name: 'Kitapanen',
      link: 'https://www.instagram.com/p/CH5WJJXHSLB/',
      description: `This is my first project in mobile application development. Kitapanen is an idea from my team which aims to improve the economy of local farmers with an intermediary medium called an application called Kitapanen.`,
      role: 'Full Stack Developer',
      technology: 'React Native, PHP, MySQL',
    },
  ])

  // function
  const handleShow = data => {
    setShow(true);
    setSelectData(data);
  }

  return (
    <>
      <Helmet>
        <title>Mobile Dev | Labieb Hammam Nurri</title>
      </Helmet>
      <div className="MobilePage">
        {
          data.map((data, i) => (
            <MainCard 
              key={i}
              img={data.img}
              title={data.name}
              handler={() => handleShow(data)}
            />
          ))
        }
        {
          show ?
          <MainModal 
            {...selectData}
            handleOffset={() => setShow(false)}
          /> : null
        }
      </div>
    </>
  );
}

export default MobilePage;