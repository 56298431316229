import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import './style.scss';

export function UpButton(props) {

  const [show, setShow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const handleTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'}); //to top
  }

  useEffect(() => {
    setShow(scrollPosition > 400 ? true : false);
    console.log(scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return show ? (
    <button className="UpButton" onClick={handleTop}>
      <FaArrowUp className="icon" />
    </button>
  ) : null;
}