import React from 'react';
import './style.scss';

export function MainCard({ img, title, handler }) {
  return (
    <div className="MainCard" onClick={handler}>
      <img src={img} />
      <div className="main-card-title">{title}</div>
    </div>
  );
}