import React, { useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import './style.scss';

export function MainModal({ handleOffset, img, name, link, description, role, technology }) {
  return (
    <div className="MainModal">
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-header">
            <img alt="img" src={img} />
            <a href={link} target="_blank" className="modal-img-link">{link}</a>
            <button className="modal-back" onClick={handleOffset}><FaArrowLeft /></button>
          </div>
          <div className="modal-detail">
            <h1 className="modal-title">{name}</h1>
            <p className="modal-description">{description}</p>
            <p className="modal-role">Role : {role}</p>
            <p className="modal-role">Technology : {technology}</p>
          </div>
        </div>
        <div className="modal-offset" onClick={handleOffset}></div>
      </div>
    </div>
  );
}