import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ContentChatapp, ContentDashgraph, ContentDashneo, ContentHimaka, ContentLogboxEo, ContentTravelkuy, ContentYaya, ContentBantai, ContentOutgoing, ContentTimur, ContentFions, ContentIntense } from '../../assets/web-content';
import { MainCard } from '../../components/Cards';
import { MainModal } from '../../components/Modals';
import './style.scss';

function WebPage({  }) {

  // state
  const [show, setShow] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const [data, setData] = useState([
    {
      img: ContentIntense,
      name: 'Intense Gaming Indonesia',
      link: 'https://intensegaming.id/',
      description: `Intense Gaming Indonesia is a website that provides interesting information about Video Games, Anime/Manga, and Techonolgy which is always updated and easy to read by any group. In this project, I was tasked with slicing the website design that had been made and was also asked to create a content management system.`,
      role: 'Full Stack Developer',
      technology: 'React JS, Node.js, Express.js, MySQL',
    },
    {
      img: ContentLogboxEo,
      name: 'LOGBOX-EO',
      link: 'https://logbox-eo.netlify.app',
      description: 'This website is created based on my own project which is intended for Event Organizer businesses as a place to showcase their work. In this project I learned a lot about using code structures in React JS.',
      role: 'UI/UX Designer, Front End Developer',
      technology: 'React JS',
    },
    {
      img: ContentChatapp,
      name: 'CHAT APP',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:6777797398426202112/',
      description: 'This website is made based on my own project to create a website-based chat application using MERN Stack and add Socket.io to handle realtime data from the server to the client side. There are many things that must be learned after creating this web application, especially in realtime data creation.',
      role: 'UI/UX Designer, Full Stack Developer',
      technology: 'React JS, Node.js, Express.js, MongoDB, Socket.io',
    },
    {
      img: ContentFions,
      name: 'FIONS STORE',
      link: 'https://fionsstore.com/',
      description: `FIONS STORE is an online shop that sells all kinds of clothing. In this project I was asked to implement a design that had been made by the client and was also asked to create a content management system so that the company's products and information could be changed.`,
      role: 'Full Stack Developer',
      technology: 'React JS, PHP, MySQL',
    },
    {
      img: ContentTravelkuy,
      name: 'TravelKuy',
      link: 'https://logbox-travel.netlify.app/',
      description: 'This website is made based on my own project in order to implement the results of learning SASS which still has not maximized the functions given and file sharing is still messy. What I feel using SASS is that it makes writing CSS easier. And this is my 2nd web design.',
      role: 'UI/UX Designer, Front End Developer',
      technology: 'HTML, SASS, jQuery',
    },
    {
      img: ContentYaya,
      name: 'YAYA',
      link: 'https://logbox-yaya.netlify.app/',
      description: `This website is created based on my own project in order to implement CSS Layouting knowledge, namely Flexbox and also to better understand the use of existing properties in CSS. Besides that, it is also to familiarize yourself with writing and understanding the use of functions that exist in jQuery. And this is my own first project outside of campus assignments or on other people's orders`,
      role: 'UI/UX Designer, Front End Developer',
      technology: 'HTML, CSS, Bootstrap 4, jQuery',
    },
    {
      img: ContentTimur,
      name: 'Timur Art Global',
      link: 'https://rhaln.github.io/TimurArtGlobal/TimurArtGlobal/index.html',
      description: `This is my second slicing website design project. In this project, I really learned about the procedures for making a website from image to html and in this project I also became more and more convinced to become a Front End Developer.`,
      role: 'Front End Developer',
      technology: 'HTML, CSS, Bootstrap 4, jQuery',
    },
    {
      img: ContentBantai,
      name: 'BANTAI',
      link: 'https://rhaln.github.io/BANTAI/BANTAI/index.html',
      description: `This is my first slicing website design project. In this project, I started to really learn about how to set the correct website layout and learned a lot about the use of CSS and this project I also got first paid as a front end developer.`,
      role: 'Front End Developer',
      technology: 'HTML, CSS, Bootstrap 4, jQuery',
    },
    {
      img: ContentOutgoing,
      name: 'Outgoing Mail App',
      link: '#',
      description: `A practical work project that was asked to create a website-based application for outgoing mail in Pagerwangi Village and this is my first project as a Full Stack Developer to handle all of the development of this application.`,
      role: 'Full Stack Developer',
      technology: 'HTML, CSS, Bootstrap 4, PHP, MySQL',
    },
    {
      img: ContentHimaka,
      name: 'HIMAKA',
      link: 'https://himaka.unikom.ac.id/',
      description: `This is my first project in designing a website using a content management system platform, namely WordPress. In this project I was entrusted with taking care of everything HIMAKA designing, including managing the website. In this HIMAKA website project, I am tasked with building a landing page.`,
      role: 'WordPress Developer',
      technology: 'WordPress',
    },
  ])

  // function
  const handleShow = data => {
    setShow(true);
    setSelectData(data);
  }

  return (
    <>
      <Helmet>
        <title>Web Dev | Labieb Hammam Nurri</title>
      </Helmet>
      <div className="WebPage">
        {
          data.map((data, i) => (
            <MainCard 
              key={i}
              img={data.img}
              title={data.name}
              handler={() => handleShow(data)}
            />
          ))
        }
        {
          show ?
          <MainModal 
            {...selectData}
            handleOffset={() => setShow(false)}
          /> : null
        }
      </div>
    </>
  );
}

export default WebPage;